import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();

  const emailInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  const submitButtonRef = useRef(null);

  const navToRegistration = () => {
    navigate('/register');
  };
  
  const navToDashboard = () => {
    navigate('/Dashboard/Landing');
  };

  const onCheckClick = () => {
    setRememberMe(!rememberMe);
  };

  useEffect(() => {
    const handleEnterKey = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        submitButtonRef.current?.click();
      }
    };

    if (emailInputRef.current) {
      emailInputRef.current.addEventListener("keydown", handleEnterKey);
    }
    if (passwordInputRef.current) {
      passwordInputRef.current.addEventListener("keydown", handleEnterKey);
    }

    return () => {
      if (emailInputRef.current) {
        emailInputRef.current.removeEventListener("keydown", handleEnterKey);
      }
      if (passwordInputRef.current) {
        passwordInputRef.current.removeEventListener("keydown", handleEnterKey);
      }
    };
  }, []);  

  useEffect(() => {
    const sessionToken = localStorage.getItem('sessionToken');
    if (sessionToken) {
      const url = new URL('/api/auth/sessionVerify', window.location.origin);
      url.searchParams.append('token', sessionToken);

      fetch(url, {
        method: 'GET',
        headers: { 'Accept': 'application/json' },
      })
      .then(response => response.json())
      .then(data => {
        if (data.expired) {
          console.log("Session expired, please log in again.");
        } else {
          navToDashboard();
        }
      })
      .catch(error => {
        console.error("Error verifying session:", error);
      });
    }
  }, []);

  const onButtonClick = () => {
    setEmailError("");
    setPasswordError("");

    if (email === "") {
      setEmailError("Please enter your email");
      return;
    }

    if (password === "") {
      setPasswordError("Please enter a password");
      return;
    }

    const url = new URL('/api/auth/loginVerify', window.location.origin);

    fetch(url, {
      method: 'POST',
      headers: { 
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: email,
        password: password,
        rememberMe: rememberMe
      })
    })
    .then(response => response.json())
    .then(data => {
      if (data.token) {
        localStorage.setItem('sessionToken', data.token);
        navToDashboard();
      } else {
        setPasswordError("Invalid email or password");
      }
    })
    .catch(error => {
      setPasswordError("Invalid email or password");
    });
  };

  return (
    <div className='body-box'>
      <div className="login-box">
        <h2>LOGIN</h2>
        <form action="" method="">
          <div className="user-box">
            <input
              ref={emailInputRef}
              value={email}
              placeholder='Username or Email'
              onChange={ev => setEmail(ev.target.value)}
              className="user-input"
            />
            <label className='errorLabel'>{emailError}</label>
          </div>
          <div className="user-box">
            <input
              ref={passwordInputRef}
              value={password}
              placeholder='Password'
              onChange={ev => setPassword(ev.target.value)}
              className='pass-input'
              type='password'
            />
            <label className='errorLabel'>{passwordError}</label>
          </div>
          <input
            onClick={onCheckClick}
            className="inputCheck"
            name='check'
            id='check'
            type="checkbox"
            checked={rememberMe}
            onChange={onCheckClick}
          />
          <label className='checkLabel'>Remember Me</label><br></br>
          <input
            ref={submitButtonRef}
            onClick={onButtonClick}
            className="inputButton"
            type="button"
            value="Submit"
          />
        </form>
        <div>
          <button className="navButton" onClick={navToRegistration}>Register this Screen</button>
        </div>
      </div>
      <div className="picture-box"></div>
    </div>
  );
}

export default Login;