import React, { useState } from 'react';

const FileUploadForm = () => {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState('');

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      setMessage('Please select a file before submitting.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    const sessionToken = localStorage.getItem('sessionToken');

    try {
      const url = new URL('/api/system/r2/postR2Object', window.location.origin);
      url.searchParams.append('domain', window.location.origin);
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${sessionToken}`
        }
      });

      if (response.ok) {
        setMessage('File uploaded successfully!');
      } else {
        const errorText = await response.text();
        setMessage(`Failed to upload file: ${errorText}`);
        console.log(errorText);
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div style={{ maxWidth: '500px', margin: '0 auto', textAlign: 'center' }}>
      <h2>Upload File to R2 Bucket</h2>
      <form onSubmit={handleSubmit}>
        <div style={{ marginBottom: '1rem' }}>
          <input type="file" onChange={handleFileChange} />
        </div>
        <button type="submit" style={{ padding: '0.5rem 1rem' }}>
          Upload
        </button>
      </form>
      {message && <p style={{ marginTop: '1rem' }}>{message}</p>}
    </div>
  );
};

export default FileUploadForm;